.ratingBox > span > span >span > span{
    /* background-color: rgb(255, 153, 0) !important; */
}
.FormSectionContainer{
    width: 100%;
    height: 100vh;
    /* background-color: var(--bg1); */
    background-image: url(../../images/Hospitality.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4vmax;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}
.FormSectionContainer > .box1{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
.FormSectionContainer > .box1 > img{
    width: 80%;
}
.FormSectionContainer > .box2{
    width: 50%;
    padding-top: 4vmax;
    padding-right: 1vmax;
    padding-left: 10vmax;
    /* background-color: ghostwhite; */
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* justify-content: center; */


}
.FormSectionContainer > .box2 > h2{
    color: rgb(77, 77, 77);
    font: 900 1.4vmax 'Poppins';
    font-family: 'Poppins';
}
.FormSectionContainer > .box2 > form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.6rem;
    margin: 1vmax 0;
    padding-top: 1vmax;
    padding-left: 0vmax;
    padding-right: 8vmax;
}
/* .FormSectionContainer > .box2 .inpStyle{ */
.FormSectionContainer > .box2 > form > select,
    .FormSectionContainer > .box2 > form > input{ 
        height: 3vmax;
        padding: 0.8vmax;
        font: 600 1vmax 'poppins';
        color: var(--textC1);
        outline: none;
        border-radius: 0.2rem;
        border: none;
        background-color: white;


}
.doblable{
    font: 600 0.9vmax 'poppins';
    color: var(--textC1);
}
.dob{
    width: 100%;
    padding: 0.8vmax;
    font: 600 1vmax 'poppins';
    color: var(--textC1);
    outline: none;
    border-radius: 0.2rem;
    border: none;
    background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat;
   
  }
  .dob::placeholder{
    font-size  : 17px;
     font-style : normal;
     font-weight: 400;
  }
  [type="date"] {
  }
  
  .dob::-webkit-inner-spin-button {
    display: none;
  }

.ratingBox{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    padding: 1vmax;
    border: 2px solid white;
    background-color: white;
    border-radius: 0.2rem;
    color: rgb(39, 39, 39);
    font: 600 1vmax 'poppins';



}
.FormSectionBtn{
    background-color: tomato !important;
    color: white !important;
    transition: all 0.5s;
}
.FormSectionBtn:hover{
    background-color: rgb(246, 65, 33);
}
@media screen and (max-width:650px){
    .FormSectionContainer{
    width: 100%;
    height: 80vh;
    /* background-color: ; */
    padding: 2vmax;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
}
.FormSectionContainer > .box1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-top: 14%;
}

.FormSectionContainer > .box1 > img{
    width: 80%;
}
.FormSectionContainer > .box2{
    width: 100%;
    padding: 1vmax;
}
.FormSectionContainer > .box2 > h2{
    /* color: rgb(46, 45, 45); */
    font: 900 2vmax 'Poppins';
}
.FormSectionContainer > .box2 > form > select,
    .FormSectionContainer > .box2 > form > input{
        height: 5.5vmax;
        font: 600 1.9vmax 'poppins';
     
    }
    .doblable{
        font: 600 1.8vmax 'poppins';
        color: var(--textC1);
    }
.FormSectionContainer > .box2 > form{

    padding: 2vmax 0;
}
.ratingBox{
    font: 600 2vmax 'poppins';
 
}

}