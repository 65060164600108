:root {
/* 
    --bgGlow: #9E2D44;
    --bgGlowTrans: #f6eaeddb;
    --bgGlowLight: #F6EAED; */

    
    --bgGlow: #a8774a;
    --bgGlowTrans: #f9f2ecd6;
    --bgGlowLight: #efe7da;
    
    
    --text: #474747;
    --black: #242424;
    --white: rgb(255, 255, 255);
    
    --btn: #EFA26F;

    --product1: #eeded0;
    --product2: #f0e5d4;
    --product3: #f0ead7;
    --product4: #f0d8db;


    /* --glowHover: rgb(118, 118, 118); */
    /* --sideBar: #9E2D44; */
}
@import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');

@font-face {
    font-family: 'Repro';
    /* Name the font */
    src: url('../../icons//ABC\ Repro/ABC\ Repro/ABCRepro-Screen-Trial.otf'),
    /* src: url('../../icons/Slabo_27px/Slabo27px-Regular.ttf'), */
        /* Specify the font file path */
        /* url('path/to/your/font.woff') format('woff'); You can include multiple formats for cross-browser compatibility */
        /* font-weight: normal;*/
        /* font-style: normal;  */
}

.waitListBox {
    width: 100%;
    display: flex;
}

.sideBar {
    width: 25%;
    height: 100vh;
    padding: 2vmax;
    padding-top: 2.6vmax;
    background-color: var(--white);
    /* display: flex;
    flex-direction: column; */
    position: fixed;
    /* overflow: auto; */
}
.logoBox{
    margin-bottom: 1.5vmax;
}
.crossBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.crossBtn {
    display: block;
    background-color: var(--btn);
    /* padding: 0.2vmax 1vmax; */
    width: 35px;
    height: 30px;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 50% 50%;
}

.sideBox1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    background-color: var(--bgGlowLight);
    padding: 2vmax;
    margin-bottom: 3vmax;
    gap: 1.5rem;
}

.sideBox1>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3vmax;
    background-color: var(--white);
    border-radius: 0.2rem;

    /* gap: 7rem; */
    >h4 {
        width: 50%;
        text-align: center;
        font: 300 1vmax 'Slabo';
        /* border: 1px solid gainsboro; */

    }

    >p {
        /* border: 1px solid gainsboro; */
        /* padding: 0.3vmax; */
        /* background-color: white; */
        /* border-radius: 0.2rem; */
        text-align: center;
        width: 50%;
        font: 300 0.9vmax 'Slabo';

    }
}

.sideBox2 {
    padding: 2vmax 0;
    border-top: 0.5px solid rgb(235, 235, 235);
    width: 100%;
}

.joinFormBox {
    width: 100%;
}

.joinForm {
    width: 100%;
    padding: 1.5vmax;
    padding-top: 0;
    /* padding-right: 0; */
    border-radius: 0.8rem;
    /* background-color: rgb(216, 209, 195); */
    /* background-image: linear-gradient(49deg, rgba(199, 185, 158, 0.181), rgba(222, 212, 188, 0.181), rgba(210, 186, 210, 0.181)); */
    border: 1px solid var(--bgGlow);
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    z-index: 99;
    >h4 {
        font: 300 1.2vmax 'Slabo';
        padding: 0.2rem;
        text-align: left;
    }
    >p{
        font: 300 0.9vmax 'Slabo';
        color: gray;
        /* padding: 0.7rem; */
    }

}

.formInput {
    width: 100%;
    padding: 0.8vmax 0.8vmax;
    border-radius: 0.4rem;
    font-family: 'Slabo';
    border: none;
    outline: none;
    font: 300 1vmax 'Slabo';
    background-color: var(--bgGlowLight);
    color: gray;
    
    /* background-color: rgb(255, 255, 255); */
}

.formInput::placeholder{
    color: gray;
}
.submitBtn {
    width: 100%;
    margin-top: 0.2vmax;
    padding: 0.4vmax 0.8vmax;
    border-radius: 0.4rem;
    font: 300 1vmax 'Slabo';

    border: none;
    outline: none;
    background-color: var(--btn);
    color: white;
}

.submitBtn:hover {
    transition: all 0.5s;
    color: white;
    background-color: var(--bgGlowLight);


}

/* ---------------------------------------------------------------------------------------- */
.wl-left-box {
    /* background-color: var(--bgGlowLight); */
    background-color: rgb(255, 255, 247);

    width: 100%;
    min-height: 100vh;

    /* padding: 2vmax; */
    padding-left: 25%;
    padding-top: 0.2%;


}

.topImgTag {
    display: block;
    width: 100%;
    height: 120vh;

    background-image: url(https://thefragrens.com/cdn/shop/files/lavbs_900x.jpg?v=1678877219);
    /* background-image: url(); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.topImgTagBg {
    width: 100%;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.151);
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 4vmax 2vmax;


}

.joinBox {

    border: 1px solid rgb(255, 255, 255);
    background-color: var(--bgGlowTrans);
    width: 45%;
    padding: 2vmax;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    z-index: 1;

    >h5 {
        text-align: center;
        font: 600 1vmax 'Slabo';
        background-color: var(--btn);
        padding: 0.8vmax;
        border-radius: 0.4rem;
        color: var(--white);
    }

    >p {
        text-align: left;
        font: 300 1vmax 'Slabo';
    }
    
}

.joinBtnx {
    border: none;
    background-color: var(--white);
    padding: 1vmax;
    border-radius: 0.4rem;
    color: var(--bgGlow);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    margin: 0 2vmax;
}
.joinBtnx:hover{
    background-color: var(--bgGlow);
    color: var(--white);

}

.pointBtn {
    display: block;
    width: 6px;

    height: 6px;
    border-radius: 50%;
    background-color: green;
    box-shadow: 0 0 10px rgb(0, 36, 0);
    animation: glow 1.5s ease-in infinite;
}

@keyframes glow {
    to {
        box-shadow: 0 0 0px rgb(0, 36, 0);

    }

    from {
        box-shadow: 0 0 10px rgb(0, 36, 0);

    }
}

.imgTag {
    width: 100%;



}

.videoBox {
    width: 100%;
    padding: 4vmax;

    >video {
        width: 100%;
    }
}

.joinWL {
    width: 100%;
    padding: 2vmax;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.5rem;

    >p {
        text-transform: uppercase;
        font-size: small;
        font-weight: 600;
    }
}

.joinBtnWl {
    background-color: var(--btn);
    color: white;
    padding: 1vmax 4vmax;
}

.joinBtnWl:hover {
    cursor: pointer;
    transition: all 0.5s;
    background-color: var(--bgGlow);

}

.footer {
    background: var(--bgGlowLight);
    width: 100%;
    display: flex;
    padding: 3vmax;
}

.footerItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    >div>a {
        font-family: 'Slabo';
        color: gray;
        text-decoration: none;
        color: var(--bgGlow);
    }

}

.navLinks {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    > a{
        color: var(--black) !important;
    }
}

.iconLinks {
    display: flex;
    gap: 0.8rem;

    

    >a svg {
        color: var(--black);
        font-size: 20px;
    }
    >a svg:hover {
        color: var(--white);
        font-size: 20px;
    }
}

.iconLinks>a:hover,
.navLinks>a:hover {

    transition: all 0.5s;
    color: var(--white) !important;
}

/* productBox */

.productBox {
    width: 100%;
    padding: 2vmax;
    /* border: 1px solid gainsboro; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.product-item {
    width: 46%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;


}

.product-content>h3 {
    font-family: 'Slabo';
    padding-bottom: 0.5rem;

}

.product-content>p {
    font-family: 'Slabo';
    text-align: justify;
    color: gray
}

/* shopifyTag */
.shopifyTag {
    width: 100%;
    height: 80vh;

    background-image: url('../../images//waitlist/p2.webp');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.shopifyTagBg {
    width: 100%;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.456);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8vmax 4vmax;


}

.shopifyJoinBox {
    border: 1px solid rgb(255, 255, 255);
    /* background-color: var(--white); */
    width: 38%;
    padding: 2vmax;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    >h5 {
        text-align: center;
        font: 600 1vmax 'Slabo';
        color: white;
    }

    >p {
        text-align: left;
        font: 300 1vmax 'Slabo';
        text-align: center;
    }
}

.shopJoinBtn {
    border: none;
    text-decoration: none;
    padding: 1vmax;
    border-radius: 0.2rem;
    background-color: var(--btn);
    color: white;
    font-family: 'Slabo';
    font-weight: 600;
}

.shopJoinBtn:hover {

    transition: all 0.5s;
    background-color: var(--bgGlow);

}

.mobileView {
    display: none;
}


.joinSticky {
    font-family: 'Slabo';
  display: none;
}
.joinFormSticky{
    display: none;
}
.joinFormBoxMV{
    display: none;
}
.formContent{
    /* background-color: var(--bgGlowLight); */
    /* padding: 3vmax 2vmax; */
    border-radius: 0.4rem;
    >p{
        padding: 0.5vmax 0;
        font-family: 'Slabo';
        text-align: justify;
        color: var(--bgGlow);
    }
}

@media screen and (max-width:750px) {
    .waitListBox {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .sideBar {
        display: none;
        width: 100%;
        height: auto;
        padding: 2vmax;
        padding-top: 13vmax;
        background-color: var(--white);
        /* display: flex;
        flex-direction: column; */
        position: relative;
    }

    .sideBox1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: space-between;
        background-color: var(--bgGlowLight);
        padding: 3vmax;
        margin-bottom: 4vmax;
        gap: 1.5rem;
    }

    .sideBox1>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1vmax;
        background-color: var(--white);
        border-radius: 0.2rem;

        /* gap: 7rem; */
        >h4 {
            width: 50%;
            text-align: center;
            font: 300 2vmax 'Slabo';
            /* border: 1px solid gainsboro; */

        }

        >p {
            /* border: 1px solid gainsboro; */
            /* padding: 0.3vmax; */
            /* background-color: white; */
            /* border-radius: 0.2rem; */
            text-align: center;
            width: 50%;
            font: 300 1.6vmax 'Slabo';

        }
    }

    .sideBox2 {
        padding: 4vmax 0;
        border-top: 0.5px solid rgb(235, 235, 235);
        width: 100%;
    }

    .joinForm {
        width: 100%;
        padding: 2vmax;
        padding-top: 0;
        /* padding-right: 0; */
        border-radius: 0.8rem;
        /* background-color: rgb(216, 209, 195); */
        /* background-image: linear-gradient(49deg, rgba(199, 185, 158, 0.181), rgba(222, 212, 188, 0.181), rgba(210, 186, 210, 0.181)); */
        border: 1px solid var(--bgGlow);
        background-color: white;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        >h4 {
            font: 300 2.2vmax 'Slabo';
            padding: 0.7rem;
        }
        >p{
            font: 300 2.2vmax 'Slabo';
            color: gray;
            padding: 0.7rem;
        }

    }

    .formInput {
        width: 100%;
        padding: 2.5vmax 2vmax;
        border-radius: 0.4rem;
        font-family: 'Slabo';
        border: none;
        outline: none;
        font: 300 2.2vmax 'Slabo';
        background-color: var(--bgGlowLight);
        color: var(--black);
        /* background-color: rgb(255, 255, 255); */
    }
    .formContent{
        background-color: var(--bgGlowLight);
        padding: 3vmax 2vmax;
        border-radius: 0.4rem;
        margin-top: 0.2vmax;
        >p{
            padding: 0.9vmax 0;
            color: var(--black);
        }
    }

    .submitBtn {
        width: 100%;
        margin-top: 1vmax;
        padding: 1vmax 2vmax;
        border-radius: 0.4rem;
        font: 600 2vmax 'Slabo';

        border: none;
        outline: none;
        background-color: var(--btn);
        color: white;
    }

    /* ---------------------------------------------------------------------------------------- */
    .mobileView {
        display: block;
    }

    .wl-left-box {
        /* background-color: var(--bgGlowLight); */
        background-color: rgb(255, 255, 247);

        width: 100%;
        min-height: auto;
        padding: 0vmax;
        padding-left: 0vmax;
        padding-top: 7%;


    }

    .topImgTag {
        display: none;
        width: 100%;
        height: auto;

        background-image: url(https://thefragrens.com/cdn/shop/files/lavbs_900x.jpg?v=1678877219);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .topImgTagBg {
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0.151);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8vmax 4vmax;
    }

    .joinContainer {
        padding: 1vmax;
    }

    .joinBox {
        /* margin: 1vmax; */
        border: 1px solid rgb(255, 255, 255);
        background-color: var(--bgGlowTrans);
        width: 100%;
        padding: 3vmax;
        border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        >h5 {
            text-align: center;
            font: 600 2vmax 'Slabo';
        }

        >p {
            text-align: left;
            font: 300 1.8vmax 'Slabo';
        }
    }

    /* productBox */
    .productBox {
        width: 100%;
        padding: 1vmax;
        /* border: 1px solid gainsboro; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    /* shopifyTag */
    .shopifyTag {
        width: 100%;
        height: auto;

        background-image: url(https://thefragrens.com/cdn/shop/files/lavbs_900x.jpg?v=1678877219);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .shopifyTagBg {
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0.151);
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding: 8vmax 4vmax; */


    }

    .shopifyJoinBox {
        border: 1px solid rgb(255, 255, 255);
        /* background-color: var(--white); */
        width: 80%;
        padding: 2vmax;
        border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        >h5 {
            text-align: center;
            font: 600 2.2vmax 'Slabo';
            color: white;
        }

        >p {
            text-align: left;
            font: 300 1vmax 'Slabo';
            text-align: center;
        }
    }

    .shopJoinBtn {
        border: none;
        text-decoration: none;
        padding: 1.5vmax;
        border-radius: 0.2rem;
        background-color: var(--btn);
        color: white;
        font-family: 'Slabo';
        font-weight: 600;
    }

    .shopJoinBtn:hover {

        transition: all 0.5s;
        background-color: var(--bgGlowLight);

    }

    

.joinSticky {
    width: 100%;
    padding: 0.4vmax;
    position: absolute;
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2vmax;


    >button {
        border: none;
        background-color: var(--bgGlow);
        border: 2px solid white;
        color: var(--white);
        font: 600 2.2vmax 'Slabo';
        padding: 1.2vmax 4vmax;
        border-radius:2rem;
        text-align: center;
        box-shadow: 0 0 18px gray;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.joinFormSticky{
    display: block;

    width: 100%;
    height: 100vh;
    padding: 2vmax;
    position: absolute;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.282);
}
.joinFormBoxMV{
    display: block;
    width: 100%;
    /* height: 70vh; */
    padding-bottom: 4vmax !important;
}

}