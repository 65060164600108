.BenContainer{
    width: 100%;
    padding: 4vmax;
    display: flex;
    gap: 2rem;
}
.benefitsContainer{
    padding: 2vmax 1vmax;
}
.benefitsContainer > h1{
    background-color: rgb(255, 128, 0);
    padding: 1vmax;
    font-family: 'Poppins';
    color: white;
    border-radius: 0.4rem;
}
.contentBox > h1{
    font: 600 2.2vmax 'Poppins';
    color: var(--textC1);
    padding: 0.2vmax ;
}

.contentBox > ul{
    padding: 1.5vmax;
    margin-top: 1vmax;
    height: 38vh;
    overflow: auto;
    border-top: 1px solid rgb(234, 234, 234);
}
.contentBox > ul::-webkit-scrollbar{
    width: 3px;
    background-color: white;
}
.contentBox > ul::-webkit-scrollbar-thumb{
    background-color: rgba(255, 211, 167, 0.311);
}
.contentBox > ul >li{
    padding: 0.5vmax 0;
    font: 300 1.1vmax 'Poppins';
    text-align: justify;
}
.imgBox > img{
    border-radius: 0.4rem;
}
.productsBox{
    width: 30%;
    height: 100vh;
    padding: 3vmax 0vmax;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    
}
.productsBox > a{
    width: 100%;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--textC1);
    font-family: 'poppins';
    font: 600 1.05vmax 'Poppons';
    /* text-align: left; */

}
.productsBox > h2{
    width: 100%;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--textC1);
    font-family: 'poppins';
    font: 600 1.4vmax 'Poppons';
    padding: 0.5vmax;
    border-bottom: 1px solid rgb(234, 233, 233);

}
.productsBox > a:hover{
    color: rgb(255, 121, 19);
}

@media screen and (max-width:600px) {
    .BenContainer{
        width: 100%;
        padding: 0.5vmax;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .benefitsContainer{
        padding: 2vmax 0.5vmax;
    }
    .contentBox > ul{
        padding-left: 3vmax;
    }
    .contentBox > ul > li{
            font: 300 2vmax 'Poppins';
            line-height: 22px;
    }
    .productsBox{
        width: 100%;
        height: auto;
        padding: 3vmax 1vmax;
        display: flex;
        /* flex-direction: column; */
        flex-direction: row;
        flex-wrap:wrap;
        gap: 1rem;
        text-align: center;
        align-items: center;
        /* justify-content: center; */
        
    }
    .productsBox > a{
        width: 45%;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--textC1);
        font-family: 'poppins';
        font: 600 1.8vmax 'Poppons';
        text-align: left;
    
    }
    .productsBox > h2{
        width: 100%;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--textC1);
        font-family: 'poppins';
        font: 600 2.2vmax 'Poppons';
        padding: 0.9vmax;
        border-bottom: 1px solid rgb(234, 233, 233);
        text-align: left;
    
    }
    
}