.white-hide{
    width: 60%;
    height: 10%;
    position: absolute;
    top: 0;
    z-index: 999;
    left: 20%;
    background-color: rgb(255, 255, 255);
}

.tabsContainer{
    width: 100%;
    /* background-color: var(--bgGlow); */
    
}
.tabsBox{
    width: 100%;
    padding: 1vmax 1vmax;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.tabsBox > a{
    text-decoration: none;
    width: 18%;
    height: 40vh;
    /* background-image: linear-gradient( var(--bgGlowLight), rgb(255, 238, 238)); */
    display: flex;
    align-items: center;
    padding: 0.7vmax;
    justify-content: center;
    box-shadow:  0 0 10px rgb(175, 175, 175);

}
.tabs{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.312);
    height: 100%;
    padding: 1vmax 2vmax;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color: var(--bg1);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.3vmax;
}
.tabsBox > a:nth-child(3){
    height: 50vh !important;
    /* background-color: aqua; */
}
.tabsBox > a:nth-child(2){
    height: 45vh !important;
    /* background-color: aqua; */
}

.tabsBox > a:nth-child(4){
    height: 45vh !important;
    /* background-color: aqua; */
}
.tabsBox > a:hover{
    transition: all 0.8s;
    /* box-shadow:  0 0 10px rgb(255, 0, 0); */
    cursor: pointer;
    /* transform: rotate(5deg); */
    /* transform: skew(2deg); */
    .arrow{
        transition: all 0.5s;
        /* color: rgb(106, 106, 106); */
        font-size: 36px;
        transform: rotate(-90deg);
    }
    
}

.b1{
    background-image: url(../../images/tabs/b1.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.b2{
    background-image: url(../../images/tabs/b2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.b3{
    background-image: url(../../images/tabs/b6.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.b4{
    background-image: url(../../images/tabs/b4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.b5{
    background-image: url(../../images/tabs/b7.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}


@media screen and  (max-width:900px){

    .white-hide{
        width: 60%;
        height: 10%;
        position: absolute;
        top: 0;
        z-index: 999;
        left: 20%;
        background-color: rgb(255, 255, 255);
        display: none;
    }
    .tabsBox{
        width: 100%;
        padding: 1vmax 1vmax;
        min-height: 70vh;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    .tabsBox > a{
        text-decoration: none;
        width: 90%;
        height: 40vh;
        /* background-image: linear-gradient( var(--bgGlowLight), rgb(255, 238, 238)); */
        display: flex;
        align-items: center;
        padding: 0.7vmax;
        justify-content: center;
        box-shadow:  0 0 10px rgb(175, 175, 175);
    
    }
    .tabs{
        width: 100%;
       
        font-size: 1.9vmax;
    }
    .tabsBox > a{
       
        .arrow{
            transition: all 0.5s;
            /* color: rgb(106, 106, 106); */
            font-size: 20px;
        }
        
    }
    .tabsBox > a:hover{
       
        .arrow{
            transition: all 0.5s;
            /* color: rgb(106, 106, 106); */
            font-size: 26px;
            transform: rotate(-90deg);
        }
        
    }
    
}