.downNav {
    position: absolute;
    z-index: 99;
    width: 230px;
    top: 3.6vmax;
    margin-left: -5vmax;
    background-color: white;
    /* box-shadow: 0 1px 5px gray; */
    border-radius: 0.05rem;
    display: flex;
    flex-direction: column;
    animation: topNav 0.2s ease-in-out;
}

.downNavHide {
    display: none;
}

.downNav>a {
    padding: 1vmax;
    text-decoration: none;
    color: var(--textC1);
    border-bottom: 1px solid rgb(229, 229, 229);
}

.downNav>a:hover {
    background-color: var(--bg2);
    color: var(--textC2);
}

* {
    margin: 0;
    padding: 0;
    max-width: 100%;
    box-sizing: border-box;
}

body {
    /* background: linear-gradient(var(--bgBody1),var(--bgBody2)); */
    /* background-color: var(--bgBody1); */
    /* background-color: rgb(201, 201, 201); */

}

:root {

    --bg1: rgb(255, 255, 255);
    --bg2: rgb(35, 35, 35);
    --textC1: rgb(56, 56, 56);
    --textC2: rgb(218, 218, 218);
    --textC3: rgb(255, 255, 255);
    --textC4: rgb(12, 12, 12);
    --hoverText: rgb(118, 118, 118);
    --hoverborder: rgb(181, 204, 255);
}

.HeaderContainerMain {
    /* border-top: 4px solid gray; */
}

.logoColor {
    color: red;
}

.logoColor2 {
    color: black;
}

.HeaderContainer {
    width: 100vw;
    padding: 1.2rem 2vmax;
    /* background: linear-gradient(to right ,var(--bg1), var(--bg2)); */
    background-color: white;
    display: flex;
    justify-content: space-between;
    /* border-top: 2px solid gray; */
    /* position: absolute; */
    position: fixed;
    z-index: 99;
}

.linkBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.activee {
    /* background-color: none !important; */
    border-bottom: 5px solid var(--hoverborder) !important;
    color: var(--hoverText) !important;
}

.aDiv,
.linkBox>a {
    text-decoration: none;
    font-family: 'Poppins';
    color: var(--textC1);
    border: 2px solid var(--border);
    padding: 0.5rem 1rem;
    font-size: 15px;
    transition: 0.5s ease-in-out;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.linkBox>a:hover {
    /* border-bottom:  5px solid var(--hoverborder) ; */
    color: var(--hoverText);
}

.iconBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.iconBoxx,
.iconBox>a {
    text-decoration: none;
}

.iconBox>a>svg {
    color: var(--textC1);
    font-size: 1.5vmax;
    transition: all 0.5s;
}

.iconBoxx>a>svg {
    color: var(--textC1);
    font-size: 3.5vmax;
    transition: all 0.5s;
}

.iconBoxx>a {
    margin: 0 1rem;
}

.iconBox>a>svg:hover {
    color: black;
    /* font-size: 1.5vmax; */
}

.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.menu>svg {
    font-size: 4vmax;
    color: rgb(33, 33, 33);
}

.menuContainer {
    width: 100vw;
    max-width: 100%;
    height: 92vh;
    position: absolute;
    z-index: 999;
    /* top: 12vh; */
    /* background: linear-gradient(var(--bg1), var(--bg2)); */
    background-color: var(--bg1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;


}

.menuContainer>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.menuContainer>div>a {
    font-size: 2vmax;
    color: var(--textC1);
    font-weight: 600;
    padding: 0.5rem;
    text-transform: uppercase;
    text-decoration: none;

}

.menuContainer>div>a:hover {
    color: gray;
}

.menulogoBox {
    width: 100%;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    border-bottom: 1px solid gray;

}

.menulogoBox>p {
    font-size: 0.7rem;
    text-align: center;
    color: var(--textC1);
    font-weight: 600
}

.menulinkBox {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 1.5rem;
    margin: 3rem 0;

}

.menulinkBox>div {}

.menulinkBox>div>a {
    width: 100;
    text-decoration: none;
    font-family: Sansita;
    color: var(--textC1);
    /* border: 2px solid black; */
    padding: 0.5rem 0.2rem;
    font-size: 15px;
    transition: 0.5s ease-in-out;
    font-weight: 600;
    text-align: center;
    margin: 10px;
}

.menuiconBox {
    padding: 0.2rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding: 1rem 0;
}

.menulinkBox>div>a:hover {
    color: var(--hoverText);
    padding-left: 0.5rem;
}

.hide {
    transition: all 0.4s ease-in-out;
    top: -100%;
}

.show {
    transition: all 0.4s ease-in-out;
    top: 10.8vh;
}

.brandName {
    width: 100%;
    text-align: center;
    padding: 0.2rem 0;
    text-align: center;
    color: var(--textC1);
    font-size: 0.8rem;
}

/* .brandName>h3{
    width: 100%;
   
} */

@media screen and (max-width:952px) {
    .HeaderContainer {
        width: 100vw;
        padding: 1.8rem;

    }

    .menu {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .linkBox {
        display: none;
    }

    .iconBox {
        display: none;
    }

    .brandName {
        width: 100%;
        text-align: center;
        color: var(--textC1);
        font-size: 0.6rem;
    }
}

@media screen and (min-width:952px) {
    .menuContainer {
        padding: 1vmax;
        display: none;
    }
}