.ProductTypesContainer{
    display: flex;
    flex-direction: column;
    margin: 2vmax 0;
}
.productTypesRow{
    display: flex;
    padding: 0.8vmax;

}
.productTypesItem{
    width: 50%;
    height: 57vh;
    padding: 0.5vmax ;
}
.productTypesItemBoxUrl1{
    background: url(../../images/aromatherapy.jpeg);
}
.productTypesItemBoxUrl2{
    background: url(../../images/Hospitality.jpeg);
}
.productTypesItemBoxUrl3{
    background: url(../../images/tutorials.jpeg);
}
.productTypesItemBoxUrl4{
    background: url(../../images/Corporate-gifting.jpeg);
}
.productTypesItemBox{
    width: 100%;
    height: 100%;
    /* background: url(../../images/banner2.jpeg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding-bottom: 0vmax;
    border-radius: 0.5vmax;
    transition: all 1s;
    /* opacity: 0.5; */
}
.productTypesItemBox:hover{
    box-shadow: 0 1px 10px rgb(39, 39, 39);
    /* background-position:105%; */
    background-size: 109%;
    /* transform: ; */
    /* background-position: center; */
    /* background-image: ; */
    /* backface-visibility: hidden; */
    

}


.productTypesItemBox >span >h2{
    color: var(--textC3);
    font-size: 1.8vmax;
    margin-bottom: 0.4rem;
    opacity: 1 !important;
}
.productTypesItemBox > span{
    width: 100%;
    text-align: center;
    background-color: black;
    padding: 1vmax 3vmax;
    padding-bottom: 1vmax;
    /* border-radius: 4rem; */
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
}
.productTypesItemBox > span > span{

    border: 2px solid var(--textC3);
    padding: 0.3vmax 3.5vmax;
    border-radius: 2rem;
    color: var(--textC3);
    font-size: 1.5vmax;
    font-weight: 500;
    transition: all 0.5s;
}
.productTypesItemBox > span >span:hover{
    /* color: gray; */
    padding: 0.3vmax 4vmax;
}

@media screen and (max-width:600px){
    .ProductTypesContainer{
        display: flex;
        flex-direction: column;
        margin: 2vmax 0;
    }
    .productTypesRow{
        display: flex;
        flex-direction: column;
        padding: 0.8vmax;
    
    }
    .productTypesItem{
        width: 100%;
        height: 40vh;
        padding: 0.5vmax ;
    }
    .productTypesItemBox:hover{
        box-shadow: 0 1px 10px rgb(39, 39, 39);
        /* background-position:105%; */
        background-size: 150%
        /* transform: ; */
        /* background-position: center; */
        
    
    }
    .productTypesItemBox > span >h2{
        /* color: white; */
        font-size: 2.8vmax;
    }
    .productTypesItemBox > span >span{
    
        /* border: 2px solid white; */
        padding: 0.3vmax 3.5vmax;
        border-radius: 2rem;
        /* color: white; */
        font-size: 2.2vmax;
        font-weight: 500;
        transition: all 0.5s;
    }
    
}