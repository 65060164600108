.modalContainer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.544);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 99;
    padding: 2vmax;

}

.hide {
    margin-top: -130%;
    transition: all 0.9s;
    /* display: none; */
}

.modalbox {
    background-color: rgb(255, 255, 255);
    /* border: 1px solid tomato; */
    width: 30%;
    height: auto;
    padding: 0.5rem;
    border-radius: 0.4rem;
    box-shadow: 1px 1px 10px tomato;

}

.modalHeader {
    display: flex;
    justify-content: space-between;


}

.modalHeader>h3 {
    color: gray;
}

.modalHeader>button {
    border: none;
    background-color: rgb(227, 227, 227);
    padding: 0.4rem;
    border-radius: 0.2rem;
    color: rgb(85, 85, 85);
    cursor: pointer;
}

.modalHeader>button:hover {
    transition: all 0.5s;
    background-color: gray;
    color: white;
}

.modalItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* max-height: 90vh; */
    padding: 1vmax;
    text-align: center;
}

.modalItem>img {
    width: 100%;
}

.modalItem>div {
    padding: 1vmax;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.yesBtn {
    height: 2.5vmax;
    text-decoration: none;
    padding: 0.5rem 2vmax;
    border: none;
    background-color: tomato;
    border-radius: 0.4rem;
    color: white;
}

.modalItem>h2 {
    color: gray;

    font: 600 1.6vmax 'Poppins';
}

.noBtn {
    height: 2.5vmax;

    text-decoration: none;
    padding: 0.5rem 2vmax;
    border: none;
    background-color: rgb(231, 231, 231);
    border-radius: 0.4rem;
    color: rgb(115, 115, 115);
}

.yesBtn:hover {
    cursor: pointer;
    background-color: rgb(222, 93, 70);
}

@media screen and (max-width:600px) {

    .modalbox {
        background-color: rgb(255, 255, 255);

        width: 100%;
        height: auto;
        padding: 0.5rem;
        border-radius: 0.4rem;

    }

    .hide {
        margin-top: -300%;
        transition: all 0.9s;
        /* display: none; */
    }
    .modalItem>div {
        padding: 1vmax;
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }
    
    .modalItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* max-height: 90vh; */
        padding: 4vmax 2vmax;
        text-align: center;
        gap: 1rem;
    }
    .modalItem>h2 {
        color: gray;
    
        font: 600 2.5vmax 'Poppins';
    }

    .yesBtn {
        height: 5.5vmax;

    }

    .noBtn {
        height: 5.5vmax;
    }
}