.SlideItem > h3 > a{
    text-decoration: none;
    color: gray;

}
       /*  Gifting secsion    */
            .giftContainer{
                width: 100vw;
                padding: 0vmax 3vmax;
                padding-bottom: 2vmax;
            }
            .giftBox{
                background-color: white;
                width: 42%;
                padding: 1vmax;
                /* height: 45vh; */
                text-align: left;
                margin-top: -1.5vmax;
            }
            .giftBox > img{
                width: 100%;
                /* height: 90%; */
            }
       /* Gifting secsion end   */


.productBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3vmax;
}
.productItem{
    display: flex;
    padding-top: 4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

}
.productItem > div{
    width: 50%;
}
.productImage{
    display: flex;
    justify-content: end;
    /* top: 0; */
    /* background-color: black; */
    
}
.productImage > img{
    margin-top: -4%;
    /* margin-left: 20%; */
    width: 80%;
    height: 80%;
    /* text-align: center; */
    /* margin: 0 auto; */
    /* border: 1px solid red; */
}
.productContain{
    display: flex;
    flex-direction: column;
     padding-right: 12vmax;
    gap: 1.2rem;
}
.productContain > h2{
    font-family: 'Poppins';
    color: rgb(37, 37, 37);

}
.productContain > p{
    font-size: medium;
    color: rgb(56, 56, 56);
    font-family: 'Poppins';

}
.productContain > a{
    text-decoration: none;
}
.productContain > a > span{
    position: relative;
    top: 20px;
    border: 3px solid gray;
    padding: 1vmax 5vmax;
    margin-top: 1vmax;
    color: gray;
    transition: all 0.5s;
    font-weight: 500;
    border-radius: 2rem;
    font-family: 'Roxborough';

}
.productContain > a > span:hover{
    box-shadow: 0.1px 0 4px rgb(10, 55, 48);
    /* font-size: ; */
    color: black;


}
.headingBox{
    font-size: 1.6vmax;
    color: rgb(37, 37, 37);
}

/* productbox2 */

.productbox2{
    display: flex;
    /* margin-bottom: 3vmax ; */
    margin: 8vmax 0;
}
.productDetail{
    width: 35%;
    padding: 1vmax 2vmax;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.productDetail > h1{
    font-family: 'Poppins';

}
.productDetail > p{
    font-family: 'Poppins';
    color: rgb(71, 71, 71);

}
.productDetail > span{
    width: 60%;
    border: 3px solid gray;
    text-align: center;
    padding: 1vmax;
    border-radius: 2rem;
    font-size: 1vmax;
    font-weight: 500;
    color: gray;
    font-family: 'Poppins';

}
.productDetail > span:hover{
    box-shadow: 0.1px 0 4px rgb(10, 55, 48);
    /* font-size: ; */
    color: black;
}
.productScroll{
    /* max-width: 65%; */
    max-width: 65vw;
    /* max-height: 30vh; */
    padding: 1vmax 1vmax;
    display: flex;
    flex-direction: row;
    word-wrap: normal;
    gap: 1rem;
    /* border: 1px solid gray; */
    overflow-x: auto;
}
.productScroll::-webkit-scrollbar {
    /* width: 0px; Mostly for vertical scrollbars */
     height: 0px; /* Mostly for horizontal scrollbars */
    /* border-radius: 5px; */
  }
  .productScroll::-webkit-scrollbar-thumb { /* Foreground */
    background: #999;
  }
  .productScroll::-webkit-scrollbar-track { /* Background */
    background: #333;
  }
.SlideItem{
    min-width: 20vw ;
    height: 38vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2vmax;
    /* background: url(../../images/DeepBlue-5ml-Deepbluerub3.jpg);
    background-position-y: 15px;
    background-position-x: -2.5vmax;
    background-repeat: no-repeat;
    background-size: contain; */
    /* background-color: red; */
    /* border: 2px solid gray; */
    transition: all 0.5s;
}
.SlideItem:hover{
    /* background-position-y: 0px; */
    margin-top: -10px;
    /* box-shadow: 1px 1px 10px gray; */
    /* border-bottom: 4px solid gray ; */

}
.SlideItem > h3{
    color: rgb(94, 94, 94);
    font-family: 'Poppins';

}
.Scrollborder{
    width: 5vw;
    height: 43%;
    /* background-color: black; */
    /* color: white; */
    display: flex;
    justify-content: center;
    align-items:end;
    position:absolute;
    left: 65%;
    z-index: -1;
    /* top: 0; */
    

}
.Scrollborder > div{
    width: 100%;
    height: 4px;
    /* border: 4px solid gray; */
    /* padding: 1vmax; */
    border-radius: 1rem;
    background-color: rgb(116, 100, 100);
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* productbox2 end */

/* villageBannarContainer */

    .villageBannarContainer{
        width: 100%;
        height: 120vh;
        background:url(../../images/sourcing_only_the_best.jpg) ;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        /* align-items:; */
        flex-direction: column;
        justify-content: space-between;
        padding: 2vmax 6vmax;
        padding-bottom: 6vmax;

    }
    .villageBannarBox1{
        padding: 3vmax 0;
        padding-right: 40%;
    }
    .villageBannarBox1 > h1{
        font-size: 5vmax;
        color: black;
        font-family: 'Poppins';

    }
    .villageBannarBox1 > p{
        font-size: 1.6vmax;
        color: black;
        font-family: 'Poppins';
        font-weight: 600;
        padding-left: 3vmax;


    }
    .villageBannarBox{
        /* height: 50%; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid gray; */
        gap: 1rem;
    }

    .villageItem{
        width: 50%;
        /* height: 28vh; */
        display: flex;
        background-color: white;
        padding: 0.6vmax;
        border-radius: 0.3rem;
        box-shadow: 1px 1px 10px rgb(0, 0, 0);

        
    }
    .villageItem .imageBox{
        width: 40%;
        /* padding: 4px; */
        /* border: 1px solid gray; */
    }
    .villageItem .imageBox > img{
        width: 100%;
        height: 100%;
    }
    .villageItem .contentBox{
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-left: 0.6vmax;
  

    }
    .villageItem .contentBox > h3{
        color: rgb(31, 30, 30);
        font-family: 'Poppins';
        font-weight: 900;
        font-size: 1.3vmax;

    }
    .villageItem .contentBox > p{
        font-size: 1vmax;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: rgb(5, 5, 5);
        font-family: 'Poppins';
        font-weight: 600;

    }
    .villageItem .contentBox > span{
        font-size: large;
        font-weight: 600;
        color: rgb(36, 71, 73);
        font-family: 'Poppins';
        transition: all 0.5s;


    
    }
    .villageItem .contentBox > span:hover{
        color: rgb(75, 0, 145);
        
    }

/* villageBannarContainer end */
@media screen and (max-width:600px){
    .productItem{
        display: flex;
        flex-direction: column;
        padding: 4rem 0.5rem;
    
    }
    .headingBox{
        font-size: 2.3vmax;
        color: rgb(37, 37, 37);
        text-align: center;
    }
    .productItem > div{
        width: 100%;
    }
    .productImage{
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid black; */
    }
    .productContain{
        display: flex;
        flex-direction: column;
        padding: 2vmax;
        gap: 1.2rem;
    }
    .productContain > h2{
        font-size: large;
        font-family: 'Poppins';

    }
    .productContain > p{
        font-size: medium;
        font-family: 'Poppins';
    }
    .productContain > a > span{
        position: relative;
        top: 20px;
        border: 3px solid gray;
        padding: 1.1vmax 6vmax;
        margin-top: 1vmax;
        color: gray;
        font-size: 2.2vmax;
    }

/* productbox2  */


    .productbox2{
        display: flex;
        flex-direction: column;
        margin: 3vmax 0;
    }
    .productDetail{
        width: 100%;
        padding: 1vmax 2vmax;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .productDetail > span{
        width: 60%;
        border: 3px solid gray;
        text-align: center;
        padding: 1.4vmax;
        border-radius: 2rem;
        font-size: 2vmax;
        font-weight: 500;
        color: gray;
    }
    .productScroll{
        /* max-width: 65%; */
        margin: 3vmax 0;
        max-width: 100vw;
        /* max-height: 50vh; */
        padding: 1vmax 1vmax;
        display: flex;
        /* flex-direction: column; */
        /* flex-direction: row; */
        word-wrap: normal;
        gap: 3rem;
        /* border: 1px solid gray; */
        overflow-x: scroll;
        /* scrollbar-color: blue; */

    }

    .SlideItem{
        min-width: 100vw;
        height: 45vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2vmax;
        /* background: url(../../images/DeepBlue-5ml-Deepbluerub3.jpg);
        background-position-y: 15px;
        background-position-x: -5vmax;
        background-repeat: no-repeat;
        background-size: contain; */
        /* background-color: red; */
        /* border: 2px solid gray; */
        transition: all 0.5s;
    }
    .Scrollborder{
        width: 12vw;
        height: 48%;
        display: flex;
        justify-content: center;
        align-items:end;
        position:absolute;
        left: 45%;


        
    
    }
    .Scrollborder > div{
        width: 100%;
        height: 4px;
        border: 3px solid black;
        /* padding: 1vmax; */
        border-radius: 1rem;
        background-color: rgb(116, 100, 100);
        text-align: center;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .SlideItem > h3{
        text-align: center;
        padding-right: 2.5vmax ;
    }
    .SlideItem:hover{
        /* background-position-y: 0px; */
        margin-top: 0px;
        /* box-shadow: 1px 1px 10px gray; */
        /* border-bottom: 4px solid gray ; */
    
    }
/* productbox2 end */

/* villageBannarContainer */

    .villageBannarContainer{
        width: 100%;
        height: 120vh;
        /* background:url(../../images/gettyimages-170498166-1024x1024.jpg) ; */
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: end;
        padding: 2vmax 1vmax;
        padding-bottom: 6vmax;

    }
.villageBannarBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid gray; */
    gap: 1rem;
}
  .villageItem{
        width: 100%;
        /* height: 26vh; */
        display: flex;
        background-color: white;
        
    }
      .villageItem .contentBox{
        width: 60%;
        padding: 1vmax;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }
    .villageItem .contentBox > p{
        font-size: 1.5vmax;
    }
    .villageItem .contentBox > h3{
        font-size: 2vmax;
    }
    .villageBannarBox1{
        padding: 3vmax 0;
        padding-right: 10%;
    }
    .villageBannarBox1 > h1{
        font-size: 8vmax;
        /* color: white; */
        font-family: 'sans-serif';

    }
    .villageBannarBox1 > p{
        font-size: 2.4vmax;
        /* color: white; */

    }
    /*  Gifting secsion    */
    .giftBox{
        width: 100%;

    }
/* Gifting secsion end   */
}