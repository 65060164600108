.productMySwiper {
    width: 95%;
    margin: 0 2.5%;



}
.p-bg1{
    padding: 1vmax 0;
    background-color:  var(--product1);

}
.p-bg2{
    padding: 1vmax 0;
    background-color:  var(--product2);

}
.p-bg3{
    padding: 1vmax 0;
    background-color:  var(--product3);

}
.p-bg4{
    padding: 1vmax 0;
    background-color:  var(--product4);

}
@font-face {
    font-family: 'Repro';
    /* Name the font */
    src: url('../../icons//ABC\ Repro/ABC\ Repro/ABCRepro-Screen-Trial.otf'),
        /* Specify the font file path */
        /* url('path/to/your/font.woff') format('woff'); You can include multiple formats for cross-browser compatibility */
        /* font-weight: normal;*/
        /* font-style: normal;  */
}

.productheading {
    width: 100%;
    /* padding-top: 2.5vmax; */
    text-align: center;

    >h4 {
        color: var(--bgGlow) !important;
        text-align: left;

        padding: 0.7vmax 2.5vmax;
        font-family: 'Slabo';
        /* background-color: var(--bgGlowLight); */
    }
}
.product1{
    /* background-color: var(--product1); */
}
.product2{
    background-color: var(--product2);
    color: var(--bgGlow) !important;
}
.product3{
    background-color: var(--product3);
}
.product4{
    background-color: var(--product4);
}

.productItem {
    padding: 4vmax;
    /* border: 1px solid gray; */
    /* background-color: var(--bgGlowLight); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: relative;
    z-index: 99 !important;
    /* margin-top: 2; */
}

.swiper-button-next,
.swiper-button-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(85, 74, 0, 0.664);
    font-weight: 900;
    /* background-color: rgb(228, 228, 228); */
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 20px !important;

}

.swiper-button-disabled {
    /* background-color: rgb(255, 248, 202); */
}

.product1-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1vmax 5vmax;
    /* background-color: antiquewhite; */

    >h3 {
        font-family: 'Slabo';
        color: var(--bgGlow);

    }

    >p {
        font-family: 'Slabo';
        color: rgb(93, 93, 93);
        text-align: center;

    }


}

.product2-img {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    >img {
        width: 100%;
        mix-blend-mode: darken;
        /* border-radius: 50%; */
    }

}

@media screen and (max-width:750px) {
    .productMySwiper {
        width: 100%;
        margin: 2vmax 0;

        /* background-color: rgb(255, 248, 202); */


    }

    .productItem {
        padding: 6vmax;
        /* border: 1px solid gray; */
        /* background-color: var(--bgGlowLight); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        position: relative;
        z-index: 99 !important;
    }

    .product1-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        >h3 {
            font-family: 'Slabo';
            text-align: center;
            width: 100%;
        }

        >p {
            font-family: 'Slabo';
            color: rgb(93, 93, 93);
            text-align: justify;

        }
    }

    .product2-img {
        width: 100%;

        >img {
            width: 100%;
        }

    }


}