.howToContainer{
    width: 100%;
    padding: 2vmax 7vmax;
}

.howToContainer > h2{
    background-color: rgb(255, 128, 0);
    padding: 1vmax;
    color: white;
    text-align: center;
    margin-bottom: 1vmax;
    border-radius: 0.4rem;
    font-family: 'poppins';
}
.topContain{
    width: 100%;

}
.topContain > p{
    font: 500 1.2vmax 'Poppins';
    color: var(--textC4);
    line-height: 30px;
    /* letter-spacing: 1.2px; */
}
.topContain > img{
    width: 100%;
    padding: 3vmax;
    padding-top: 1vmax;
}
.topicalAppBox{
    padding: 2vmax 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
        /* border-bottom: 1px solid rgb(235, 234, 234); */



}
.contentBox{
    width: 65%;
}
.imgBox{
    width: 35%;
}
.contentBox > h1{
    font: 600 2vmax 'Poppins';
    color: var(--textC1);
}
.contentBox > p{
    font: 300 1.2vmax 'Poppins';
    color: var(--textC1);
    line-height: 26px;
    text-align: justify;
}
.css-1aquho2-MuiTabs-indicator{
    background-color: rgb(255, 145, 0) !important;
}
.MuiTab-textColorPrimary{
    color: gray !important;
}
@media screen and (max-width:600px){
    .howToContainer{
        width: 100%;
        padding: 0vmax;
    }
    .howToContainer > h2{
        background-color: rgb(255, 153, 0);
        padding: 1vmax;
        color: white;
        text-align: center;
        margin-bottom: 0vmax;
    }
    .topicalAppBox{
        padding: 2vmax 1vmax;
        display: flex;
        flex-direction: column-reverse;
        /* align-items: center; */
        justify-content: space-between;
        gap: 2rem;
border-bottom: 1px solid rgb(235, 234, 234);
    
    
    }
   
    .contentBox{
        width: 100%;
    }
    .imgBox{
        width: 100%;
    }
    .contentBox > h1{
        font: 600 3vmax 'Poppins';
        color: var(--textC1);
        text-align: center;
    }
    .contentBox > p{
        font: 300 1.99vmax 'Poppins';
        color: var(--textC1);
        line-height: 24px;
        text-align: justify;
    }
/* ------------------ */

    .topContain{
        width: 100%;
        padding: 1vmax;
    
    }
    .topContain > p{
        font: 500 2.2vmax 'Poppins';
        color: var(--textC4);
        line-height: 30px;
        letter-spacing: 1.2px;
        text-align: justify;
    }
    .topContain > img{
        width: 100%;
        padding: 0vmax;
        padding-top: 1vmax;
    }
}